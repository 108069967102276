.subject-selecting {
  font-family: 'Roboto', sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-touch-callout: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.subject-selecting h1 {
  color: #202124;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.subject-slide {
  padding: 10px;
}

.subject-image {
  height: 300px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.subject-image h2 {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 20px;
  border-radius: 4px;
}




.start-button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #1980e6;
  color: white;
}


.start-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.start-button:hover:not(:disabled) {
  opacity: 0.9;
}

.subject-image {
  height: 50vh;
  width: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}


.subject-slide h2 {
  color: white;
  font-size: 45px; /* 기본 20px에 뷰포트 너비의 2%를 더함 */
  background-color: rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 5px;

  margin: 0;
  text-align: center;
  max-width: 600px; /* 최대 너비 설정 */
}



.start-button {

  transform: translateY(30%);
  z-index: 10;
  margin-bottom: 20px;
}
