.saved-quizzes-container {
  font-family: 'Roboto', sans-serif;
  max-width: 600px; /* 문제 푸는 페이지와 동일한 폭 */
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.loading-container {
  font-family: 'Roboto', sans-serif;
  max-width: 600px; /* 문제 푸는 페이지와 동일한 폭 */
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.quiz-solving-container {
  font-family: 'Roboto', sans-serif;
  max-width: 600px; /* 문제 푸는 페이지와 동일한 폭 */
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e08e;

}

.nav-item {
  padding: 10px 20px;
  border: 0px solid #dadce0;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f9f9f9;
  color: #202124;
}

.nav-item.selected {
  background-color: #1a73e8;
  color: white;
}

.quiz-list {


  list-style-type: none;
  padding: 0;
}

.quiz-card {
  display: flex;
  align-items: center;
  padding: 15px;
  border: 0px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  background-color: #f3f4f650;
}

.quiz-card:hover {
  background-color: #f9f9f9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.quiz-info {
  display: flex;
  width: 100%;
  align-items: center;
}

.quiz-number {
  flex: 0 0 auto;
  width: 50px; /* 최소 너비 설정 */
  font-weight: bold;
  padding: 0 10px;
  color: #007aff;
}

.quiz-question {
  flex: 2;
  padding: 0 10px;
  color: #333333;
}

.quiz-category {
  flex: 1;
  padding: 0 10px;
  color: #666666;
  text-align: right;
}
