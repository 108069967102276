/* src/pages/Home.css */

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  min-height: 100vh;
  background-image: url('/splash.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-width: 600px;
  margin: 0 auto;
  font-family: Roboto, sans-serif;
}

.home-header {
  margin-top: 110px;
  margin-bottom: 20px;
}

@media (max-width: 399px) {
  .home-header {

      margin-top: 50px;
      margin-bottom: 20px;
      
  }
}



.home-header h1 {
  font-size: 4em;
  color: #ffffff;
  margin-bottom: 5px;
}

.home-header p {
  font-size: 1.2em;
  color: #ffffffac;
  margin-bottom: 70px;
}

.home-main {
  flex: 1;
}

.home-section {
  margin-bottom: 40px;
}

.home-section h2 {
  font-size: 2em;
  color: #333;
}

.home-section p {
  font-size: 1.1em;
  color: #555;
  max-width: 600px;
  margin: 0 auto;
}

.home-button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: #0056b3;
}

.home-footer {
  margin-top: 40px;
}

.home-footer p {
  font-size: 0.9em;
  color: #999;
}