.quiz-selecting {
  font-family: 'Roboto', sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  -webkit-touch-callout: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}


.quiz-selecting h1 {
  color: #4e5966;
  font-size: 20px;
  text-align: center;
  margin-top: 0px;;
  margin-bottom: -10px;
  background-color: #f3f4f6;
  border-radius: 3px;
  padding-top: 6px;
  padding-bottom: 4px;
}

.quiz-selecting h3 {
  color: #202124;
  font-size: 19px;
  margin-bottom: 10px;
}
.navbar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap:15px;
}
.order-random-buttons {
  display: flex;
  gap: 10px;
  width: 100%;
}

.order-random-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border: 1px solid #dadce0;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  background-color: #fff;
  color: #000;
  white-space: nowrap;
  width: 100%;
  margin-bottom:  0px;
}

.order-random-button.selected {
  background-color: #1a73e8;
  color: #fff;
  border: 1px solid #1a73e8;
}

.nav-item {
  padding: 10px 20px;
  border: none !important;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #f3f4f6 !important;
  color: #4e5966 !important;
}

.nav-item.selected {
  
  background-color: #1980e6 !important;
  color: white !important;
}

.nav-item:hover {
  opacity: 0.9;
}

.label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: left;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #58626b;
  font-size: 15px;
}

.form-group select,
.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.form-group input[type="number"] {
  -moz-appearance: textfield;
}

.form-group input[type="number"]::-webkit-outer-spin-button,
.form-group input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-group button {
  margin-left: auto; /* Align button to the right */
  display: block; /* Ensure the button takes up the full width available */
}
button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #1980e6;
  color: white;
}

button:hover {
  opacity: 0.9;
}

.navbar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.order-random-buttons {
  display: flex;
  gap: 10px;
  width: 100%;
}

.order-random-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border: 1px solid #dadce0;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  background-color: #fff;
  color: #000;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 12px;
}

.order-random-button.selected {
  background-color: #1a73e8;
  color: #fff;
  border: 1px solid #1a73e8;
}

.form-group input[type="number"] {
  -moz-appearance: textfield;
  width: calc(100% - 16px); /* Adjust width to fit within parent */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.form-group input[type="number"]::-webkit-outer-spin-button,
.form-group input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-group.button-right {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
}

.form-group.button-right button {
  margin-left: auto; /* Align button to the right */
}

.num-range {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  border-bottom: 0.5px solid #e0e0e0;
  padding-bottom: 15px;

}

.category-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
p {
  font-weight: 600;
  color : #181f29;
}

.category-label {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #6c7682;
}
.num-row {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.num-item {
  display: flex;
  align-items: center;
  gap: 10px;
  width:20px
}
/* 기존 CSS 코드 생략 */

.num-item .triangle.black {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid black;
}

.num {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  background-color: #e0e0e060;
}

.num.solved {
  background-color: lightgreen;
}

.num.wrong {
  background-color: lightcoral;
}

.num.last {
  background-color: lightblue;
}
/* QuizSelecting.css */
.num-item {
  position: relative;
  cursor: pointer;
}

.num-item.selected {
  border-radius: 4px;
  background-color: #d3e3fc;
}

.num-item .triangle {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%) rotate(360deg); /* 180도 회전 */
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #1a73e8; /* 삼각형 방향 변경 */
}

.category-row {
  margin-bottom: 5px; /* 카테고리별 줄바꿈을 위한 여백 추가 */
}
/* 기존 CSS 코드 생략 */

.num-item .triangle.black {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%) rotate(360deg);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid black;
}

.category-select-all {
  padding: 5px 10px;
  background-color: #c0c0c0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 10px;
  transition: background-color 0.3s ease;
  width: 90px;
  margin-bottom: 4px;
}

.category-select-all:hover {
  background-color: #8b8b8b;
}

.category-select-all.selected {
  background-color: #8b8b8b;
}

.category-select-all.selected:hover {
  background-color: #c0c0c0;
}

.category-label input {
  width: 70%;
  padding: 8px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.recommended-quizzes {
  margin-bottom: 20px;
}

.recommended-quizzes .label {
  margin-bottom: 10px;
}

.recommendation-controls {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.recommendation-controls .category-label {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.recommendation-controls .category-label input {
  width: 100%;
  margin-top: 5px;
}

.recommendation-controls button {
  white-space: nowrap;
  height: 34px;
}

.saved-quizzes-container .quiz-list .quiz-card{
  padding-bottom: 15px !important;
}