.admin-container {
  display: flex;
  height: 100vh;
  background-color: #f5f5f5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.admin-sidebar {
  width: 190px;
  background-color: #f0f0f0;
  border-right: 1px solid #e0e0e0;
}

.admin-header {
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.admin-title {
  font-size: 1.2em;
  color: #333;
  margin: 0;
}

.admin-nav {
  padding: 10px 0;
}

.admin-nav-item {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-nav-item:hover {
  background-color: #e8e8e8;
}

.admin-nav-item.active {
  background-color: #d8d8d8;
}

.admin-nav-icon {
  margin-right: 10px;
  font-size: 1.2em;
}

.admin-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.admin-content-header {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.admin-content-header h3 {
  margin: 0;
  color: #333;
}

.admin-content-body {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}
