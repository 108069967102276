/* src/components/LoadingSpinner.css */
.loading-spinner {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
  }
  
  /* If you need to adjust the size of the Lottie animation, you can target it like this: */
  .loading-spinner > div {
    width: 100px !important;
    height: 100px !important;
  }
  