.quiz-list-container {
  font-family: 'Roboto', sans-serif;
  max-width: 1200px; /* 문제 푸는 페이지와 동일한 폭 */
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.quiz-list h1 {
  color: #202124;
  font-size: 24px;
  margin-bottom: 20px;
}

.quiz-list .navbar {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center; /* 추가된 스타일 */
}

.quiz-list .nav-item {
  padding: 10px 20px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  color: #202124;
}

.quiz-list .nav-item.selected {
  background-color: #1a73e8;
  color: white;
}

.quiz-list .label {
  font-size: 14px;
  color: #202124;
  margin-bottom: 8px;
}

.quiz-list .sort-order {
  margin-left: auto; /* 우측 정렬 */
}

.quiz-list .sort-order label {
  margin-right: 10px;
  font-size: 14px;
  color: #202124;
}

.quiz-list .sort-order select {
  padding: 5px 10px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  color: #202124;
}

.quiz-list .quiz-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quiz-list .quiz-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  background-color: #f3f4f630;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quiz-list .quiz-item:hover {
  background-color: #f9f9f9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.quiz-list .quiz-info {
  display: flex;
  width: 100%;
  align-items: center;
}

.quiz-list .quiz-number {
  flex: 0 0 auto;
  width: 50px; /* 최소 너비 설정 */
  font-weight: bold;
  padding-left: 0px;
  margin-right: -20px;
  color: #007aff;
}

.quiz-list .quiz-question {
  flex: 2;
  padding-left:2px;
  padding-right:5px;
  color: #4d5966;
  font-weight: 500;
}

.quiz-list .quiz-category {
  flex: 0.9;
  padding: 0 10px;
  padding-left: 0;
  color: #959ca1;
  text-align: right;
  font-weight: 400;
  font-size: 13px;
}

.quiz-list .quiz-actions {
  display: flex;
  gap: 5px;
}

.quiz-list .quiz-actions button {
  padding: 5px 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #1a73e8;
  color: white;
  width: 45px;
}

.quiz-list .quiz-actions button:hover {
  background-color: #185abc;
}

.quiz-list .quiz-items .quiz-item{
  margin-bottom: 0px !important;
}