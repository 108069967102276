.quiz-solving {
  font-family: 'Roboto', sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding-bottom: 30px; /* buttons의 높이보다 크게 설정 */
  scrollbar-width: none;
  -ms-overflow-style: none;
  word-spacing: 2px;
  letter-spacing: -0.5px;
}
.quiz-solving::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  background-color: #f3f4f6;
}

.quiz-solving h2 {
  color: #202124;
  font-size: 24px;
  margin-bottom: 20px;
}

.quiz-solving p {
  color: #202124;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 50px;
  line-height: 1.5em;

}

.question div span {
  font-weight: 600 !important;
}

.quiz-solving select,
.quiz-solving input {
  width: 100%;
  padding: 8px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 16px;
}

.true-false-buttons {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 55px;
}

.true-false-button{
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid #dadce0;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  background-color: #fff;
  color: #000;
  white-space: nowrap;
  width: 100%; /* Set width to 100% */
  margin-bottom: 12px;
}

.true-false-button::before{
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 1px solid #1a73e8;
  border-radius: 50%;
  margin-right: 8px;
}

.true-false-button.selected::before{
  background-color: #1a73e8;
  box-shadow: inset 0 0 0 2px #fff;
}

.true-false-button.correct{
  border: 3px solid #1a73e8;
}





.multiple-choice-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.multiple-choice-button {
  display: flex;
  align-items: center;  /* 세로 중앙 정렬 */
  padding: 8px 16px;
  border: 1px solid #dadce0;
  border-radius: 12px;
  cursor: pointer;
  font-size: 14px;
  background-color: #fff;
  color: #000;
  width: 100%;
  margin-bottom: 12px;
  text-align: left;
  min-height: 55px;
}

.multiple-choice-button::before {
  content: '';
  flex-shrink: 0;
  width: 9px;
  height: 9px;
  border: 1px solid #1a73e8;
  border-radius: 50%;
  margin-right: 11px;
  margin-left: -2px;
}

.multiple-choice-button span {
  flex-grow: 1;
  word-break: keep-all;
  white-space: normal;
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  min-height: 35px;
  line-height: 1.3; /* This increases the line spacing */
}


.multiple-choice-button.selected::before {
  background-color: #1a73e8;
  box-shadow: inset 0 0 0 2px #fff;
}

.multiple-choice-button.correct {
  border: 3px solid #1a73e8;
}

.quiz-solving .d-type-answer {
  display: flex;
  flex-direction: column;
}

.quiz-solving .d-type-answer input {
  margin-bottom: 8px;
}

.progress-bar-container {
  width: 100%;
  background-color: #e7edf3;
  height: 4px;
  border-radius: 5px;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1 !important; /* Ensure it is below the sidebar */
  position: relative; /* Ensure z-index is applied */
}

.progress-bar {
  height: 100%;
  background-color: #1980e6;
  border-radius: 5px;
  transition: width 0.3s ease;
}

.quiz-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start !important;
}
.quiz-header > * {
  flex: 0 1 auto;
}

.quiz-num {
  margin-left: 0; /* Push the category name to the right */
  font-weight: 300;
  font-size: 15px;
  color: #202124;
  margin-left: 5px;
  margin-bottom: 2px;
}

.category-name {
  margin-left: auto; /* Push the category name to the right */
  font-style: italic;
  font-size: 12px;
  color: #202124;
  font-weight: 300;
  max-width: 100%;
  word-break: break-word;
}

.quiz-tags {
  margin-left: 8px;
  font-size: 12px;
  color: #202124;
  background-color: #e7edf3;
  padding: 3px 8px;
  border-radius: 15px;
  font-weight: 300;
  margin-bottom: 2px;
}

.fill-in-the-blank {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.fill-in-the-blank span {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fill-in-the-blank input {
  width: 350px;
  height: 30px;
  border: 2px solid #e7edf3;
  border-radius: 8px;
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
}

.fill-in-the-blank button {
  width: 90px;
  height: 50px;
}

.explanation {
  margin-top: 20px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-spacing: 2px;
  letter-spacing: -0.5px;
}
.explanation.memo-section {
  border-bottom: none;
  word-spacing: 2px;
  letter-spacing: -0.5px;
}


.explanation p {
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 1.4;
  color: #838383;
  line-height: 1.5em;

}

.explanation p span{
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 1.4;
  color: #838383;
}


.explanation p.comparison {
  margin-top: 20px;
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
}

.quiz-solving .buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  bottom: 0px;
  background-color: #ffffff;
  padding: 10px 0;
  z-index: 999;
  position: sticky;
  
}


.content-container {
  position: relative;
}
.quiz-solving .btn-unknown,
.quiz-solving .btn-next,
.quiz-solving .btn-prev {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

@media screen and (max-width: 400px) {
 .quiz-solving .btn-unknown,
 .quiz-solving .btn-next,
 .quiz-solving .btn-prev {
    font-size: 13px;
    padding: 8px 15px;

 }
}



.btn-unknown {
  background-color: #e7edf3;
  color: #4e7397;
}

.btn-next,
.btn-prev {
  background-color: #1980e6;
  color: white;
}

.btn-unknown:hover,
.btn-next:hover,
.btn-prev:hover {
  opacity: 0.9;
}

.warning-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.star-icon {
  cursor: pointer;
  font-size: 15px;
  color: transparent; /* Make the fill transparent */
  transition: color 0.3s ease, transform 0.3s ease; /* Add transform transition */
  margin-left: 0px;
  display: inline-block;
  margin-bottom: 4px;
  -webkit-text-stroke: 1.3px #ffd700; /* Yellow border */
}

.star-icon.saved {
  color: #ffd700; /* Yellow color for saved star */
  -webkit-text-stroke: 1.3px #ffd700; /* Maintain the yellow border */
}



.star-icon:active {
  transform: scale(3); /* Make the star grow when clicked */
}

.memo-section {
  font-weight: 600;
  margin-top: 20px;
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
}

.memo-section p {
  margin-bottom: 10px;
}

.memo-section textarea {
  width: calc(100% - 16px); /* Match other input elements and account for padding */
  padding: 8px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 16px;
}

.memo-section button {
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.memo-section button:hover {
  opacity: 0.9;
}

.highlighted-text {
  display: inline;
  position: relative;
  padding-top: 1px;
  padding-bottom: 0px !important;
  border-radius: 3px;
  background-color: inherit;
  white-space: normal;
  word-break: normal;
  vertical-align: baseline;
}

.highlighted-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: inherit;
  opacity: 0.5;
  z-index: -1;
  border-radius: inherit;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.question {
  padding-left: 10px;
  padding-right: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.question-container,
.explanation-container {
  position: relative;
}

.button-group {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  gap: 5px;
}

.underline-button,
.remove-underline-button {
  width: auto;
  padding: 5px 10px;
  font-size: 12px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.underline-button:hover,
.remove-underline-button:hover {
  background-color: #185abc;
}

.underlined {
  text-decoration: underline;
  text-decoration-color: #1a73e8;
  text-underline-offset: 3px;
}

.underlined-text {
  text-decoration: underline;
  text-decoration-color: #1a73e8;
  text-underline-offset: 3px;
}

.additional-content {
  background-color: #f5f5f560;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  padding-top: 10px;
}

.additional-content .explanation {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}

.additional-content .memo-section {
}
.premise-container {
  background-color: #f5f5f590;
  border-radius: 8px;
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top:-15px;
  display: inline-block; /* Ensure the container adjusts its height based on content */
  width: 100%; /* Ensure it takes the full width of its parent container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
.premise-container p {
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0px;
  margin-left: 0px;
  font-weight: 400;
  font-size: 15px;
  word-spacing: 2px;
  letter-spacing: -0.5px;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.close-modal-button {

  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: black;

}

.modal-content {
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

.quiz-content .quiz-header {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 5px;
}

.quiz-header .quiz-tag {
  font-size: 0.9em;
  color: #666666
}

.quiz-category {
  font-size: 0.9em;
}

.navigation-buttons {
  display: flex;
  justify-content: flex-end;
}

.navigation-buttons button:first-child {
  margin-right: 10px;
}
.quiz-content {
  margin-top : 10px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  background-color: #f5f5f590;

}
.question_modal {
  font-size: 1.3em;
  color: #333;
  border-top: 1px solid #00000050;
  padding-top: 15px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.quiz-content p {
  line-height: 1.4;
  margin-bottom: 20px;
  font-weight: 400;
}

.quiz-content strong {
  color: #1875d8;
}

.similar-rank {
  font-size: 0.8em;
  font-weight: 300;
}
.quiz-content h3 {
  margin-top: 5px;
}
.premise-container p,
.question,
.explanation p,
.comparison p,
.multiple-choice-button span {
  white-space: pre-line;
  word-break: keep-all;
  overflow-wrap: break-word;
  line-height: 1.5;
}
