.quiz-result-analysis {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 800px; /* 전체 틀의 너비를 줄임 */
  margin: 0 auto; /* 가운데 정렬 */
}

.result-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.text-summary {
  font-size: 16px;
  color: #333;
}

.chart-container {
  width: 300px;
  height: 300px;
}

.quiz-result-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.quiz-item {
  margin-bottom: 20px;
}

.quiz-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column; /* 세로 정렬을 위해 추가 */
  border: 2px solid; /* 테두리 추가 */
  padding-bottom: 60px !important; 
}

.quiz-card.correct {
  border: 2px solid green;
}

.quiz-card.wrong {
  border: 2px solid red ;
}

.quiz-card.dont-know {
  border: 2px solid orange;
}

.quiz-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.quiz-header0 {
  display: flex;
  align-items: center;
  margin-right: auto;
  justify-content: space-between;
  margin-bottom: 5px;
  text-align: left;
}

.quiz-header {
  display: flex;
  align-items: center;
margin-right: auto;
  justify-content: space-between;
  margin-bottom: 10px;
}
.quiz-question {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.question-number {
  flex-shrink: 0;
  margin-right: 10px;
  line-height: 1.5;
}

.question-text {
  text-align: left;
  flex-grow: 1;
  line-height: 1.5;
}

.quiz-details {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #555;
  margin-bottom: -30px;
  margin-top: -10px;
  padding-bottom: -10px;
  gap:auto;
}

.answer-result, .stats {
  display: flex;
  flex-direction: column;
  width: 100px;
  padding-top: 10px;
}

.quiz-details p {
  margin: 5px 0;
}

.result.correct {
  color: green;
}

.result.wrong {
  color: red;
}

.result.dont-know {
  color: orange;
}


.star-icon2 {
  cursor: pointer;
  font-size: 15px;
  color: transparent; /* Make the fill transparent */
  transition: color 0.3s ease, transform 0.3s ease; /* Add transform transition */
  margin-left: 0px;
  display: inline-block;
  margin-bottom: 4px;
  -webkit-text-stroke: 1.3px #ffd700; /* Yellow border */
  margin-top: 2.5px;
  margin-right: 9px;
}

.star-icon2.saved {
  color: #ffd700; /* Yellow color for saved star */
  -webkit-text-stroke: 1.3px #ffd700; /* Maintain the yellow border */
}

.star-icon2:hover {
  color: #ffd900a0; /* Lighter yellow on hover */
}

.star-icon2:active {
  transform: scale(3); /* Make the star grow when clicked */
}


.bar-chart-container {
  margin-top: 10px;
  width: 100%;
  height: 100px;
  margin-left: 5px !important;
}


.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;

}

.retry-wrong-quizzes, .exit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.retry-wrong-quizzes {
  background-color: #36A2EB;
  color: white;
}
.spacer {
  flex-grow: 1;
}

.exit-button {
  background-color: #FF6384;
  color: white;
  margin-left: auto;

}

.retry-wrong-quizzes:hover, .exit-button:hover {
  opacity: 0.9;
}
