/* Base container */
.study-tracker-container {
  font-family: 'Roboto', sans-serif;
  margin: 0px 10px;
  padding: 30px 5px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* Header */
.study-tracker-title {
  color: #202124;
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.study-tracker-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2px auto !important;
  text-align: center;
}

/* Input section */
.input-button-container {
  display: flex;
  align-items: center;
  gap: 16px;
  max-width: 800px;
  margin: 5px auto;
}

.study-tracker-inputs {
  display: flex;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
}

.create-course-section,
.join-course-section {
  display: flex;
  gap: 8px;
  background: #ffffff;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
}

.study-tracker-input {
  flex: 1;
  height: 48px;
  padding: 0 16px;
  border: 1px solid #eceef0;
  border-radius: 12px;
  font-size: 15px;
  color: #333d4b;
  transition: all 0.2s ease;
  background: #f9fafb;
}

.study-tracker-input:focus {
  outline: none;
  border-color: #3182f6;
  background: #ffffff;
  box-shadow: 0 0 0 3px rgba(49, 130, 246, 0.1);
}

.study-tracker-input::placeholder {
  color: #adb5bd;
}

/* Buttons */
.study-tracker-button {
  height: 48px;
  padding: 0 24px;
  border: none;
  border-radius: 12px;
  background: #3182f6;
  color: white;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.study-tracker-button:hover {
  background: #1c64f2;
  z-index: 1200;
}

.study-tracker-button:active {
  background: #1a56db;
  transform: scale(0.98);
}

.view-all-button {
  padding: 10px 10px;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  background-color: #1980e6;
  color: white;
  transition: background-color 0.3s ease;
  margin-left: auto !important;
}

.view-all-button:hover {
  background-color: #0056b3;
}

/* Course section */
.study-tracker-course {
  cursor: grab;
  transition: all 0.2s ease;
  background: #ffffff;
  margin: 8px 0;
  border-radius: 8px;
  position: relative;
  z-index: 2;
}
.study-tracker-course:active .lecture-bar {
  transform: none !important;
  box-shadow: none !important;
}
.study-tracker-course:active {
  cursor: grabbing;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transform: scale(1.01);
  transform: none !important;
  box-shadow: none !important;
}

.study-tracker-course.dragging {
  opacity: 0.7;
  background: #f8f9fa;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}


.course-header {
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  position: relative;
  z-index: 3;
}

.course-title {
  padding-left: 10px;
  margin-left: 5px;
  font-size: 20px;
  color: #333;
  border-top: 2px solid #e0e0e070;
  border-bottom: 2px solid #e0e0e070;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: -15px;
  position: relative;
  z-index: 2;
}

/* Lecture blocks */
.lecture-bar {
  display: flex;
  flex-wrap: wrap;
  margin-left: 80px;
  flex-direction: row;
  margin-bottom: -10px;
  pointer-events: none;
  transform: none !important;
  transition: none !important;
}

.lecture-batch {
  display: flex;
  flex-wrap: nowrap;
  max-width: fit-content;
  margin-top: 30px;
  margin-bottom: 5px;
  margin-left: 20px;
}

.lecture-block {
  position: relative;
  border: 1px solid #dadce0;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.2s ease;
  margin: 1px;
  white-space: nowrap;
  overflow: hidden;
  margin-top: -10px;
  pointer-events: auto;

}

.lecture-block.selected {
  background-color: #1980e6;
  border-color: #0056b3;
}

.lecture-block:hover {
  background-color: rgba(25, 128, 230, 0.1);
}

.lecture-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  display: inline-block;
  border-color: #0056b3;
}

/* Progress tracking */
.progress-indicator {
  position: absolute;
  top: 50%;
  height: 3px;
  background-color: #1980e6;
  z-index: 1000;
  transform: translateY(-50%);
  margin-left: 80px;
}

.progress-log {
  white-space: nowrap;
  max-width: fit-content;
  font-size: 11px;
  color: #555;
  position: relative;
}

.progress-entry {
  display: flex;
  align-items: center;
  position: relative;
  min-width: 80px;
  text-align: right;
  justify-content: flex-end;
  margin-left: -80px;
}

.progress-date {
  font-size: 13px;
  min-width: 80px;
  text-align: right;
  font-weight: 900;
  margin-left: -37px;
}

/* Color management */
.color-legend {
  display: flex;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
  margin: 0px auto;
  max-width: 800px;
}

.color-legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  transition: transform 0.2s ease;
  position: relative;
}

.color-legend-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  z-index: 1200;
}

.color-box {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.color-picker-popup {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  gap: 5px;
  padding: 5px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1200;
}

.color-option {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
}

.color-option:hover {
  transform: scale(1.1);
}

/* Color modal */
.color-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1300;
}

.color-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.color-options {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.color-confirm-button {
  margin-top: 10px;
  padding: 5px 10px;
  background: #1980e6;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 45px;
}

.color-confirm-button:hover {
  background: #105295;
  z-index: 1200;
}

/* Misc */
.login-message {
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #666;
}

.course-code {
  font-size: 14px;
  color: #666;
  margin-right: 10px;
  padding: 2px 6px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.delete-button {
  margin-left: 5px;
  font-size: 10px;
  font-weight: 200;
  color: red;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  white-space: nowrap;
  display: inline-block;
  margin-right: -12px;
  z-index: 2001;

}
.delete-button2 {
  font-size: 10px;
  font-weight: 100;
  color: red;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  white-space: nowrap;
  display: inline-block;
  margin-right: 12px;
  z-index: 2001;

}
.memo-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: fadeIn 0.3s ease;
}

.memo-modal {
  background: white;
  padding: 12px 24px;
  border-radius: 12px;
  min-width: 400px;
  animation: slideUp 0.3s ease;
  box-shadow: 0 4px 20px rgba(0,0,0,0.15);
  transform-origin: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px) scale(0.95);
    opacity: 0;
  }
  to {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}


.memo-modal textarea {
  width: 100%;
  min-height: 100px;
  margin: auto -13px;
  padding: 12px;
  border: 1px solid #eceef0;
  border-radius: 8px;
  resize: vertical;
}

.memo-buttons {
  margin-top: 12px;
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}

.memo-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.progress-indicator {
  position: absolute;
  top: 50%;
  height: 3px;
  background-color: #1980e6;
  z-index: 1000;
  transform: translateY(-50%);
  margin-left: 80px;
  cursor: pointer;
  pointer-events: auto;

}

.progress-indicator::after {
  content: '';
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
  z-index: 999;
}
.title-dday-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dday-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dday-display {
  margin-top: 11px;
  margin-left: 50px;
  font-size: 35px;
  font-weight: bold;
  color: #1f5a95;
  min-width: 200px; /* Add fixed width */
  text-align: right; /* Align numbers consistently */
  font-family: monospace; /* Use monospace font for consistent number width */
}

.dday-input {
  padding: 8px;
  border: 1px solid #eceef0;
  border-radius: 6px;
}

.reset-dday {
  margin-top: 8px;
  padding: 4px 6px;
  border: none;
  border-radius: 4px;
  background: #ff6b6b60;
  color: white;
  cursor: pointer;
  font-size: 10px;
}

.reset-dday:hover {
  background: #ff5252;
}
.dday-input-group {
  display: flex;
  gap: 8px;
  align-items: center;
}

.confirm-dday {
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  background: #1980e6;
  color: white;
  cursor: pointer;
}

.confirm-dday:hover {
  background: #1666c0;
}
