/* src/components/CategoryManagement.css */

.category-management {
    font-family: 'Roboto', sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .category-management h1 {
    color: #202124;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .category-container {
    display: flex;
    gap: 20px;
  }
  
  .category-level {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
    padding: 16px;
  }
  
  .category-level h2 {
    color: #202124;
    font-size: 18px;
    margin-bottom: 16px;
  }
  
  .category-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .category-item span {
    flex-grow: 1;
  }
  
  .category-input {
    flex-grow: 1;
    padding: 4px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .category-button {
    background-color: #1a73e8;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 8px;
  }
  
  .category-button:hover {
    background-color: #185abc;
  }
  
  .add-category {
    margin-top: 16px;
    display: flex;
    gap: 8px;
  }
  