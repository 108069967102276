.authority-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
  }
  
  .authority-header {
    margin-bottom: 30px;
    text-align: center;
  }
  
  .view-controls {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .view-button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    background-color: #e7edf3;
    color: #4e7397;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .view-button.active {
    background-color: #1980e6;
    color: white;
  }
  
  .article-container, .group-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  

  .article-header {
    margin-bottom: 10px;
    border-bottom: 1px solid #e7edf3;
    padding-bottom: 10px;
  }
  
  .article-header h3 {
    color: #1c558d;
    margin: 0;
    font-size: 18px;
  }
  
  .article-title {
    color: #4e7397;
    font-size: 14px;
  }
  
  .content-item {
    display: flex;
    margin: 2px 0;
    font-size: 14px;
  }
  
  .content-key {
    color: #838383;
    min-width: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  
  .content-value {
    flex: 1;
    white-space: normal;
    word-break: keep-all;
    overflow-wrap: break-word;
    line-height: 1.5;
    padding-right: 10px;
    align-items: center;
    margin-top:1px;
  }
  
  .group-title {
    color: #1c558d;
    margin-bottom: 15px;
    font-size: 16px;
  }
  
  .group-article-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 0;
    border-bottom: 1px solid #f3f4f6;
    cursor: pointer;
  }
  
  .article-label {
    color: #838383;
    font-weight: 400;
    min-width: 60px;
    font-size: 14px;
  }
  
  .article-name {
    color: #4e7397;
    flex: 1;
    font-size: 14px;
    font-weight: 500;

  }

  
  .subject-controls {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .subject-button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    background-color: #e7edf3;
    color: #4e7397;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .subject-button.active {
    background-color: #1980e6;
    color: white;
  }
  
  .custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

  }
  
  .custom-modal {
    background-color: white;
    border-radius: 8px;
    max-height: 80%;
    overflow-y: auto;
    margin-left: 300px;
    position: relative;
    width: 1080px !important;
    margin: auto auto;
  }
  
  .close-modal2-button {
    position: absolute;
    top: 6px;
    right: 15px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: #838383;
    z-index: 2001;
    margin-top: 5px;
}
  
  .modal-header {
    padding: 10px;
    border-bottom: 1px solid #e7edf3;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .modal-article-label {
    font-size: 18px;
    font-weight: bold;
    color: #1c558d;
  }
  
  .modal-article-title {
    font-size: 16px;
    color: #4e7397;
  }
  

  .modal-body {
    padding: 20px;
    padding-top: 0px;
  }
  
  .modal-authorities {
    margin-bottom: 20px;
  }
  
  .authority-tag {
    display: inline-block;
    padding: 5px 6px;
    background-color: #e7edf3;
    color: #3a5775;
    border-radius: 10px;
    margin-right: 5px;
    margin-top: 5px;
    font-size: 13px;
  }
  
  .modal-text {
    line-height: 1.6;
  }
  
  .modal-text h4, .modal-authorities h4 {
    color: #376fa8;
    margin-bottom: 5px;
  }
  
  .modal-text p {
    color: #202124;
    font-size: 15px;
    font-weight: 400;
    white-space: pre-wrap;
    word-break: keep-all;
    margin-top: 5px;
  }
  .article-container, .group-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  /* Keep all existing styles and add/update these */

.group-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.group-card {
  background: white;
  border-radius: 8px;
  padding-left: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  padding-bottom: 5px;
}

.group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .toggle-button {
    background: none;
    border: none;
    color: #838383;
    cursor: pointer;
    font-size: 12px;
    padding: 5px;
  }
  
  .group-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;

  }
  
.group-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.group-article-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.group-article-item:hover {
  background-color: #e7edf347;
}

.article-info {
  display: flex;
  align-items: center;
  gap: 10px;
}





.article-section {
  color: #838383;
  font-size: 14px;
  font-weight: 500;

  padding: 2px 0px;
  background-color: #fff;
  border-radius: 4px;
}


.comparison-modal {
  width: 90%;
  max-width: 1200px;
}

.comparison-container {
  display: flex;
  gap: 20px;
}

.comparison-column {
  flex: 1;
  padding: 10px;
}

.comparison-column h3 {
  text-align: center;
  margin-bottom: 15px;
}
.title-row{
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
}
.compare-button {
  margin-left: 10px;
  border-radius: 4px;
  padding: 0 4px;
  padding-top: 4px;
  padding-bottom: 2px;
  font-size: 11px;
  font-weight: 350;
  
  cursor: pointer;
  align-items: center;
  justify-content: center;

}
.expanded-content {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1.5px solid #888888;
}



.different-value {
  background-color: #fff3cd;
  padding: 0px 4px;
  border-radius: 3px;
  margin-left: -4px;
}
.compare-button.identical {
  background-color: #20212430;
  color: white;
}

.compare-button.different {
  background-color: #007bff;
  color: white;
}
.content-item.empty {
  height: 22px;  /* Adjust this value to match your content-item height */
  visibility: hidden;
}
.highlight-authority {
  color: #ff0000;
  font-weight: bold;
}
.original-content {
  text-align: left;
  line-height: 1.8;
}

.original-content p {
  margin-top: 10px !important;
  margin-bottom: 0px !important;
  font-size: 14px;
  color: #333;
  word-break: keep-all;
  white-space: pre-wrap;
  font-weight: 400;
  line-height: 1.5;
}

.original-article {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7edf3;
}

.original-article h3 {
  color: #1c558d;
  font-size: 18px;
  margin-bottom: 8px;
}

.original-article h4 {
  color: #4e7397;
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: normal;
}
.original-content p {
  margin-bottom: 12px;
  font-size: 14px;
  color: #333;
  word-break: keep-all;
  padding-left: 10px;
}


.original-content p.indent-1 {
  padding-left: 20px;
}

.original-content p.indent-2 {
  padding-left: 30px;
}
.original-content p.indent-3 {
  padding-left: 40px;
}

.article-reference {
  color: #0066cc;
  cursor: pointer;
  text-decoration: underline;
}

.article-reference:hover {
  color: #003366;
}
.referenced-modal {
  position: fixed;
  z-index: 1000;
}

.referenced-modal .custom-modal {
  position: relative;
  width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}
.article-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 620px;
}
.article-card, .group-card {
  background: white;
  border-radius: 8px;
  padding:20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}


.group-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 620px;
}

/* Only apply to original view */
[data-view="original"] .group-card {
  width: 100%;
  flex: 0 0 auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
}
[data-view="byGroup"] .article-card, .group-card {
  background: white;
  border-radius: 8px;
  padding:5px 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.search-container2 {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 620px;
  background: transparent;
  margin: 0 auto;
  display: flex;

  flex-direction: column;
  padding:10px;
  padding-left:0px !important;
  padding-right:0px !important;

}
.article-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 620px;

}

.search-input2 {
  width: 604px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;

}

.search-dropdown2 {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow:0px 15px 15px rgba(0,0,0,0.15);
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
}


.search-result-item2 {
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}
.matched-content{
  margin-top: 5px;
  font-size: 13px;
  font-weight: 400;
  color: #000000a0;
}
.search-result-item2:hover {
  background: #f5f5f5;
}

.highlight-article {
  animation: highlight 2s;
}

@keyframes highlight {
  0%, 100% { background-color: transparent; }
  50% { background-color: #fff3cd; }
}

.authority-content{
  width: 620px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

mark {
  background-color: #fff493;  /* 노란색 */
  color: black;               /* 텍스트 색상 */
  padding: 0px;              /* 패딩 */
}
.custom-highlight mark {
  background-color: #4caf50;  /* 초록색 */
  border-radius: 2px;
  color: white;
}
.scroll-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s;
  z-index: 1000;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-top-button:hover {
  opacity: 1;
}
.patent-office-group, .trial-office-group, .common-group {
  margin-bottom: 10px;
}

.category-title {
  font-size: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
}