/* src/components/QuizManagement.css */

.quiz-management {
  font-family: 'Roboto', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.quiz-management h1 {
  color: #202124;
  font-size: 24px;
  margin-bottom: 20px;
}

.quiz-management .form-group {
  margin-bottom: 16px;
}

.quiz-management .form-group label {
  display: block;
  color: #202124;
  font-size: 14px;
  margin-bottom: 8px;
}

.quiz-management .form-group input,
.quiz-management .form-group select,
.quiz-management .form-group textarea,
.quiz-management .form-group .ql-container {
  width: 100%;
  padding: 8px;
  margin: 0;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.quiz-management .form-group .ql-container {
  height: auto;
  padding: 0;
}

.quiz-management .form-group .ql-editor {
  min-height: 100px;
  padding: 8px;
}

.quiz-management .form-group .ql-toolbar {
  border: 1px solid #dadce0;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}

.quiz-management .form-group textarea {
  height: 100px;
  resize: vertical;
}

.quiz-management .d-type-answer {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}

.quiz-management .d-type-answer input {
  flex: 1;
}

.quiz-management button {
  width: 100%;
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.quiz-management button:hover {
  background-color: #185abc;
}

.quiz-management .true-false-buttons {
  display: flex;
  gap: 10px;
}

.quiz-management .true-false-button {
  flex: 1;
  padding: 10px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  background-color: #fff;
  color: #000;
}

.quiz-management .true-false-button.selected {
  background-color: #1a73e8;
  color: white;
}

.quiz-management .true-false-button:hover {
  background-color: #185abc;
  color: white;
}

.quiz-management .selection-info {
  margin-top: 16px;
  padding: 8px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  background-color: #fff;
}

.quiz-management .selection-info p {
  margin: 0 0 8px;
  font-size: 14px;
}

.quiz-management .selection-info button {
  width: auto;
  padding: 8px 16px;
}

.quiz-management .saved-question {
  margin-top: 16px;
  padding: 8px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  background-color: #fff;
  cursor: text;
}

.quiz-management .form-group:has(.d-type-answer) > label {
  display: none;
}

.empty-field {
  border: 2px solid red !important;
}

.error {
  border: 2px solid red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.text-input-container {
  position: relative;
}

.text-input-container .editable-textarea {
  width: 100%;
  padding: 8px;
  margin: 0;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
  min-height: 100px;
  resize: vertical;
  white-space: pre-wrap;
  outline: none;
}

.text-input-container button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: auto;
  padding: 5px 10px;
  font-size: 12px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.text-input-container button:hover {
  background-color: #185abc;
}

.underlined {
  text-decoration: underline;
}

.button-group {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  gap: 5px;
}

.underline-button {
  width: auto;
  padding: 5px 10px;
  font-size: 12px;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.underline-button:hover {
  background-color: #185abc;
}

/* New styles for underlined text */
.underlined-text {
  text-decoration: underline;
}
.editor-container {
  border: 1px solid #dadce0;
  border-radius: 4px;
  padding: 8px;
  min-height: 100px;
  background-color: #fff;
}

.editor-container .public-DraftEditor-content {
  min-height: 100px;
}

.editor-container button {
  width: 100px;
  height: 30px;
  right: 10px;
  bottom: 10px;
  font-size: 12px;
  padding: 4px 8px;
  align-items: right;
  background-color: #1a73e8;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}

.editor-container button:hover {
  background-color: #185abc;
}

.content-display {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.editor-container .DraftEditor-root {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.special-char-buttons {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping to keep all buttons in one line */
  overflow-x: auto; /* Allow horizontal scrolling if needed */
  gap: 2px; /* Space between buttons */
  margin-top: 8px; /* Space above the button group */
  margin-bottom: 10px;
  justify-content: flex-start; /* Align buttons to the left */

}

.special-char-buttons .special-char-button {
  padding: 4px 4px; /* Smaller padding for compact buttons */
  font-size: 10px; /* Smaller font size for compactness */
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f0f0f0; /* Neutral background color */
  color: #333; /* Darker text color for contrast */
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  white-space: nowrap; /* Ensure text does not wrap */
}

.special-char-buttons .special-char-button:hover {
  background-color: #d9d9d9; /* Slightly darker on hover */
  color: #000; /* Ensure text is visible on hover */
}

.special-char-buttons .special-char-button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 2px 2px #aaa; /* Custom focus style */
}

.comparison-input {
  position: relative;
  z-index: 2;
}

.tag-input {
  position: relative;
  z-index: 1;
}
.editor-container {
  overflow: visible;
}
.editor-container {
  border: 1px solid #dadce0;
  border-radius: 4px;
  padding: 8px;
  background-color: #fff;
  /* height 관련 속성 제거 */
}
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 8px;
  min-height: 10px;
  height: 10px;
  resize: none;
  overflow: hidden;
  transition: height 0.2s ease;
}

.current-category-info {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  border: 1px solid #dadce0;
}

.current-category-info p {
  margin: 8px 0;
  font-size: 14px;
  color: #202124;
}

.button-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.button-container button {
  flex: 1;
  max-width: 200px;
}
.category-edit-checkbox {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.category-edit-checkbox label {
  display: flex;
  align-items: center;
}
