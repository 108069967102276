/* src/components/Navbar.css */

.nav-bar {
  
  max-width: 640px;
  background-color: #f5f5f5; /* 배경색을 흰색으로 변경 */
  padding: 0px 0px !important;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  color: black; /* 글자색을 검은색으로 변경 */
  box-sizing: border-box; /* Ensure padding is included in the width */
  top: 0; /* Position it at the very top */
  left: 0; /* Ensure it spans the entire width */
  max-height: 1000px;
  font-family: Roboto, sans-serif;

}

/* Media query for smaller screens */

.nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0px;
}

.hamburger-button {
  font-size: 1.5em;
  background: none;
  border: none;
  color: black; /* 글자색을 검은색으로 변경 */
  cursor: pointer;
  padding: 0px 0px;
  margin-left: 5px;
}

.right-sidebar-button {
  margin-left: auto;
  font-size: 1.5em;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  padding: 0px 0px;

}
.right-sidebar-button img {
  margin-bottom: -2px;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.nav-title {
  margin-left: 20px;
  font-size: 1.5em;
  color: black; /* 글자색을 검은색으로 변경 */
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 200px;
  background-color: white; /* 배경색을 흰색으로 변경 */
  color: black; /* 글자색을 검은색으로 변경 */
  z-index: 1000;
  transition: transform 0.3s ease;
}
.right-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 250px;
  background-color: white; /* 배경색을 흰색으로 변경 */
  color: black; /* 글자색을 검은색으로 변경 */
  z-index: 1000;
  transition: transform 0.3s ease;
  max-height: 1000px;
}
.sidebar {
  left: -250px;
  right: auto;
  transform: translateX(0%);
}

.sidebar.open {
  padding-left: 25px;
  transform: translateX(100%);
}


.right-sidebar {
  right: 0;
  left: auto;
  transform: translateX(100%);
  display: flex;

  align-items: center;
  flex-direction: column; /* Ensure children are stacked vertically */

}

.right-sidebar.open {
  transform: translateX(0);
  max-height: 1000px;
}

.close-sidebar {
  font-size: 1.5em;
  background: none;
  border: none;
  color: black; /* 글자색을 검은색으로 변경 */
  cursor: pointer;
  margin: 10px;
  margin-bottom: 0px !important;
  margin-right:auto;
}

.sidebar-content {
  padding: 30px;
  padding-top: 0px !important;
  z-index: 1001 !important; /* Ensure it is above other elements */
  position: relative; /* Ensure z-index is applied */
}

.search-input {
  width: 80%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-results {
  max-height: 1000px;
  overflow-y: auto;
}

.search-item {
  padding: 10px;
  border-bottom: 0.5px solid #ccc;
}

.item {
  margin: 30px 0;
  cursor: pointer;
}

.item img.svg-icon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.item .wrapper,
.item .home-wrapper,
.item .frame,
.item .frame-div {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.item .b {
  font-size: 1em;
  color: black; /* 글자색을 검은색으로 변경 */
  font-weight: 400;
}

.item a {
  color: black; /* 링크 색상을 검은색으로 변경 */
  text-decoration: none;
}

.menu-item {
  display: flex;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: none;
}

.overlay.active {
  display: block;
}
.searchLabel {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: Roboto, sans-serif !important;
}

.searchLabel span:first-child {
  font-size: 15px;
  font-weight: bold;
  color: #767982;
  margin-left: 3px;
  margin-right: 2px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-family: Roboto, sans-serif;
}

.searchLabel span:last-child {
  font-size: 14px;
  color: #76798290;
  font-weight: 500;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-family: Roboto, sans-serif;
}

.searchContent {
  font-size: 14px;
  color: #767982;
  margin-bottom: 0px !important;
  margin-top: 10px !important;
  font-weight: 500;

  font-family: Roboto, sans-serif;
}
