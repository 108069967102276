.profilePage {
  font-family: 'Roboto', sans-serif;
  max-width: 600px; /* Reduced from 800px to 600px */
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.profileHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profilePhoto {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

.profileInfo h2 {
  margin: 0;
  font-size: 24px;
}

.profileInfo p {
  margin: 0;
  color: #666;
}

.profileStats {
  margin-bottom: 20px;
}

.graphPlaceholder {
  height: 200px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.quizLists {
  display: flex;
  justify-content: space-between;
}

.savedQuizzes, .recentQuizzes {
  width: 48%;
}

.savedQuizzes ul, .recentQuizzes ul {
  list-style: none;
  padding: 0;
}

.savedQuizzes li, .recentQuizzes li {
  background-color: #f9f9f9;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .profilePage {
    padding: 15px;
  }

  .profileHeader {
    flex-direction: column;
    align-items: center;
  }

  .profilePhoto {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .quizLists {
    flex-direction: column;
  }

  .savedQuizzes, .recentQuizzes {
    width: 100%;
  }
}
