.color-picker__container {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding-top: 6px;
  border: #00000030 0px solid;
  border-radius: 4px;
  padding-left: 3px;
  padding-bottom: 3px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding-bottom: 5px;
}

.color-picker__button {
  width: 24px !important;
  height: 24px;
  border-radius: 50%;
  margin: 0 3px;
  border: none;
  cursor: pointer;

  /* Reset inherited styles */
  background: none;
  padding: 0;
  font: inherit;
  color: inherit;
  text-align: center;
  line-height: normal;
}

.color-picker__add-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
  font-size: 18px;
  cursor: pointer;
  margin-left: 3px;
  margin-right: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Reset inherited styles */
  padding: 0;
  text-align: center;
}

.color-picker__custom-picker {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.color-picker__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 40px !important;
  height: 40px !important;
  background-color: transparent;
  border: none !important;
  cursor: pointer;
  padding: 0;
  margin-bottom: 0 !important;
}

.color-picker__input::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-picker__input::-webkit-color-swatch {
  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #797979, 0 0 1px rgba(125, 125, 125, 0.3);
}

.color-picker__input::-moz-color-swatch {
  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #000000, 0 0 5px rgba(0, 0, 0, 0.3);
}

.color-picker__add-color-button {
  padding: 5px 10px;
  background-color: #1980e6;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 9px;
  align-items: center;
  margin-left: 10px;
}

.color-picker__color-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 1px;
}

.color-picker__remove-button {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px !important;
  height: 15px !important;
  padding: 0;
  border-radius: 50%;
  background-color: #ff4d4d;
  color: white;
  font-size: 10px;
  line-height: 1;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
}


.color-picker__remove-button:active {
  background-color: #e60000;
}
.color-picker__add-color-button{
  z-index: 2000;
}
.color-picker__container {
  display: flex;
  align-items: center;
  z-index: 9998;

}
.color-picker__container {
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

.color-picker__button,
.color-picker__remove-button,
.color-picker__add-button,
.color-picker__add-color-button {
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  transition: opacity 0.2s;
  z-index: 3000;
}

.color-picker__button:active,
.color-picker__remove-button:active,
.color-picker__add-button:active,
.color-picker__add-color-button:active {
  opacity: 0.7;
  z-index: 1000;
  display: flex;
  position: sticky;
}
